// src/App.js

import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [ticker, setTicker] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://watchlistbackend.brendanlarkin.com/api/add-ticker', { ticker });
      setTicker('');
    } catch (error) {
      console.error('There was an error adding the ticker!', error);
    }
  };

  return (
    <div className="App">
      <h1>Stock Watchlist</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Ticker:
          <input 
            type="text" 
            value={ticker} 
            onChange={(e) => setTicker(e.target.value)} 
            required 
          />
        </label>
        <button type="submit">Add Ticker</button>
      </form>
    </div>
  );
}

export default App;
